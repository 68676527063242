import React from "react";
import ContactTag from "../../components/ContactTag";

export function ContactTags({ ticket }) {
  return ticket.tags.map((tag) => {
    return (
      <ContactTag tag={tag} key={`ticket-contact-tag-${ticket.id}-${tag.id}`} />
    );
  });
}
