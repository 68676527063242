import { useCallback, useContext, useMemo } from "react";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import { useHistory } from "react-router-dom";

const useSelectTickets = () => {
  const { tickets, setTickets } = useContext(TicketsContext);
  const history = useHistory();

  const isSelected = useCallback(
    (ticket) => {
      if (tickets.length === 0) return false;
      return tickets.some((t) => t.id === ticket.id);
    },
    [tickets]
  );

  const select = useCallback(
    (ticket) => {
      const ticketIndex = tickets.findIndex((t) => t.id === ticket.id);
      if (ticketIndex === -1) {
        setTickets([...tickets, ticket]);
      } else {
        const newTickets = [...tickets];
        newTickets.splice(ticketIndex, 1);
        setTickets(newTickets);
      }
      history.push("/tickets");
    },
    [tickets]
  );

  const updateTags = useCallback(
    (tags) => {
      setTickets(
        tickets.map((ticket) => ({
          ...ticket,
          tags: tags
        }))
      );
    },
    [tickets]
  );

  const hasSelection = useMemo(() => tickets.length > 0, [tickets]);

  return { tickets, select, isSelected, hasSelection, updateTags };
};

export default useSelectTickets;
