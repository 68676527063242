import React, { useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import { i18n } from "../../translate/i18n";
import useSelectTickets from "../../hooks/useTickets/useSelectTickets";
import TableRowSkeleton from "../TableRowSkeleton";
import { ContactTags } from "../../pages/Contacts/ContactTags";
import { Close } from "@material-ui/icons";
import { MultiTagsContainer } from "../TagsContainer/MultiTicketsTags";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles
  },
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  }
}));

function MultiTicketManager() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const { tickets, select } = useSelectTickets();
  return (
    <>
      <MultiTagsContainer />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell>{i18n.t("contacts.table.name")}</TableCell>
            <TableCell align="center">
              {i18n.t("contacts.table.actions")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {tickets.map((ticket) => (
              <TableRow key={ticket.id}>
                <TableCell style={{ paddingRight: 0 }}>
                  {<Avatar src={ticket.contact.profilePicUrl} />}
                </TableCell>
                <TableCell>
                  {ticket.contact.name}
                  <div className={classes.tagsContainer}>
                    <ContactTags ticket={ticket} />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => select(ticket)}>
                    <Close />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {loading && <TableRowSkeleton avatar columns={3} />}
          </>
        </TableBody>
      </Table>
    </>
  );
}

export default MultiTicketManager;
