import { Chip, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import useSelectTickets from "../../hooks/useTickets/useSelectTickets";

function removeDuplicates(array = []) {
  const map = new Map();
  array.forEach((item) => {
    map.set(item.id, item);
  });
  return Array.from(map.values());
}

export function MultiTagsContainer() {
  const { tickets, updateTags } = useSelectTickets();

  const [tags, setTags] = useState([]);
  const [selecteds, setSelecteds] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      loadTags();
      const tags = removeDuplicates(
        tickets.map((ticket) => ticket.tags).flat()
      );
      console.log({ tags, tickets });
      if (Array.isArray(tickets)) {
        setSelecteds(tags);
      } else {
        setSelecteds([]);
      }
    }
  }, [tickets]);

  const createTag = async (data) => {
    try {
      const { data: responseData } = await api.post(`/tags`, data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const loadTags = async () => {
    try {
      const { data } = await api.get(`/tags/list`);
      setTags(data);
    } catch (err) {
      toastError(err);
    }
  };

  const syncTags = async (data) => {
    try {
      const { data: responseData } = await api.post(`/tags/sync`, data);
      updateTags(data.tags.filter((tag) => tag?.id));
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value, reason) => {
    let optionsChanged = [];
    if (reason === "create-option") {
      if (isArray(value)) {
        for (let item of value) {
          if (isString(item)) {
            const newTag = await createTag({ name: item });
            optionsChanged.push(newTag);
          } else {
            optionsChanged.push(item);
          }
        }
      }
      await loadTags();
    } else {
      optionsChanged = value;
    }
    setSelecteds(optionsChanged);

    await syncTags({
      ticketId: JSON.stringify(tickets.map((t) => t.id)),
      tags: optionsChanged
    });
  };

  return (
    <Paper style={{ padding: 12 }}>
      <Autocomplete
        multiple
        size="small"
        options={tags}
        value={selecteds}
        freeSolo
        onChange={(e, v, r) => onChange(v, r)}
        getOptionLabel={(option) => option.name}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              style={{
                background: option.color || "#eee",
                color: "#FFF",
                marginRight: 1,
                fontWeight: 600,
                borderRadius: 3,
                fontSize: "0.8em",
                whiteSpace: "nowrap"
              }}
              label={option.name.toUpperCase()}
              {...getTagProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Tags" />
        )}
        PaperComponent={({ children }) => (
          <Paper style={{ width: 400, marginLeft: 12 }}>{children}</Paper>
        )}
      />
    </Paper>
  );
}
